import React, { useState } from "react"
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout"
import Seo from "../components/seo"
import TopPanel from "../components/TopPanel";
import { ABOUT_PROJECT } from '../routes';
import { Section } from "../components/section";
import { SubText, Title1, Title2 } from "../components/Headings";
import { ContentWrapper } from "../components/common";
import GalleryPanel from "../components/GalleryPanel";
import ProjectAdvantages from "../components/ProjectAdvantages";
import { HalfContentWrapper } from '../components/common';
import InfoPanel from "../components/InfoPanel";
import InfoModal from "../components/InfoModal";
import projectSrc from '../images/projekt-roku-nominace-2022.png';
import Map from "../components/Map";
import MoreIndexInfo from "../components/MoreIndexInfo";
import AtelierOffer from "../components/AtelierOffer";

const PinkSection = styled.div`
  background-color: ${({theme}) => theme.colors.primary};
  display: grid;
  grid-template-columns: 1fr;
  color: ${({theme}) => theme.colors.white};

  ${Section} {
    background-color: transparent;
  }

  ${Title2}, ${SubText} {
    color: ${({theme}) => theme.colors.white};

    @media (min-width: 1200px) {
      padding-right: 5rem;
    }

    @media (min-width: 1400px) {
      padding-right: 10rem;
    }
  }

  ${SubText} {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 2rem;

    @media (min-width: 1400px) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 1.125rem;

    li {
      padding-left: 1rem;
      position: relative;
    }

    li:before {
      content: '-';
      position: absolute;
      left: 0px;
    }

    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const FullSection = styled(Section)`
  padding-top: 0;
  padding-bottom: 0;
`;

const AboutProjectWrapper = styled.div`
  padding: 3.5rem 0 1rem 0;

  @media(min-width: 768px) {
    padding: 3.5rem 0 3.5rem 0;
  }

  @media(min-width: 1200px) {
    padding: 7.5rem 0 7.5rem 0;
  }
`;

const LogoProjectWrapper = styled.div`
  img {
    position: fixed;
    bottom: 20px;
    right: 0;
    width: 100%;
    height: auto;
    max-width: 120px;
    background-color: rgba(213,213,213,0.75);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 1rem;
    z-index: 999;
  }

  @media (min-width: 768px) {
    img {
      top: 20%;
      bottom: auto;
      max-width: 150px;
      background-color: rgba(213,213,213,0.65);
    }
  }
`;

function IndexPage () {
  const [isModalOpen, setModalOpen] = useState(false)
  return (
    <Layout>
      <Seo />
      <AtelierOffer />
      {/* <LogoProjectWrapper>
        <img src={projectSrc} alt="Realitní Projekt Roku - Nominovaný projekt 2022" />
      </LogoProjectWrapper> */}
      <InfoModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
      <TopPanel
        title="Splňte si sen o moderním bydlení v&nbsp;Kolíně"
        text="Toužíte po vlastním bydlení? Nebo chcete své peníze uložit do nemovitosti, protože víte, že jde o&nbsp;bezpečnou investici? Ať jsou vaše motivy jakékoliv, přijměte pozvání do Třešňovky – prvního rezidenčního bydlení v&nbsp;Kolíně, které respektuje ty nejnáročnější požadavky na moderní a&nbsp;komfortní žití."
        btnLabel="Prozkoumejte Třešňovku"
        btnLink={ABOUT_PROJECT}>
        <StaticImage src="../images/panel-01-n.jpg" className="panel-image" placeholder="blurred" alt="Foto" />
      </TopPanel>
      <FullSection>
       <ContentWrapper>
        <InfoPanel />
          <div className="row">
            <div className="col-xs-6">
            </div>
            <div className="col-xs-6 col-lg-6 offset-lg-6">
              <AboutProjectWrapper>
                <Title1>Jméno jí dal blízký sad</Title1>
                <SubText>
                  Rezidence Třešňovka je projekt 26&nbsp;bytových jednotek, v&nbsp;němž jde minimalistický architektonický styl ruku v&nbsp;ruce s&nbsp;funkčností a&nbsp;nejnovějšími trendy.
                </SubText>
                <p>
                  <strong>Je komfortní</strong> – samozřejmostí je prostorné hlídané parkoviště.
                </p>
                <p>
                  <strong>Je velkorysá</strong> – všichni mají terasu, lodžii, balkon nebo zahrádku.
                </p>
                <p>
                  <strong>Je pohodová</strong> – součástí je venkovní dětské hřiště a&nbsp;relax zóna.
                </p>
                <p>
                  <strong>Je smart</strong> – má nabíjecí stanice, fotovoltaiku i&nbsp;přípravu na chytrou domácnost.
                </p>
                <p>
                  A&nbsp;co extra potěší? Přilehlý třešňový sad a&nbsp;dostatek zeleně, která vám mnohokrát vykouzlí úsměv na rtech.
                </p>

              </AboutProjectWrapper>
            </div>
          </div>
        </ContentWrapper>
      </FullSection>
      <GalleryPanel folder="index" data={[1,2,3,4,5,6,7,8]} />
      <ProjectAdvantages />
      <PinkSection>
        <Section>
          <HalfContentWrapper>
            <Title2>Na dobré adrese</Title2>
            <SubText>Třešňovka představuje příjemnou kombinaci bydlení v&nbsp;klidné vilové čtvrti s&nbsp;dobrou dostupností do okolních směrů:</SubText>
            <ul>
              <li>do centra Kolína dojedete za 5&nbsp;minut, do Prahy za 40&nbsp;minut</li>
              <li>v&nbsp;těsném sousedství areálu se nachází supermarket Lidl</li>
              <li>veškerá další občanská vybavenost je dostupná v&nbsp;blízkém okolí</li>
              <li>MHD zastavuje hned u&nbsp;areálu rezidence (stanice Lučební)</li>
            </ul>
          </HalfContentWrapper>
        </Section>
        <Map />
      </PinkSection>
      <MoreIndexInfo />
    </Layout>
  );
}

export default IndexPage;
